import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { HTTP_INTERCEPTORS, HttpClient, HttpRequest, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { routes } from './app.routes';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';

class CustomLoader implements TranslateLoader
{
    constructor(
        private http: HttpClient
    ) { }

    getTranslation(lang: string): Observable<any>
    {
        return this.http.get(`/assets/i18n/${lang}.json?${Date.now()}`);
    }
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideHttpClient(
            withInterceptorsFromDi(),
            withFetch()
        ),
        importProvidersFrom(TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomLoader,
                deps: [HttpClient]
            }
        })),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        },
        provideClientHydration(
            withHttpTransferCacheOptions({
                filter: (req: HttpRequest<unknown>) => true,
                includeHeaders: [],
                includePostRequests: true,
                includeRequestsWithAuthHeaders: false,
            })
        )
    ],
};

import { IKycDetailedStatusResponse, IUsersKyc } from './kyc';
import { IWallet, eWalletType } from './wallets';


export interface IUserRole
{
    id: eUserRole;
    user: string;
    role: eUserRole;
}


export enum eUserStatus
{
    active = 1,
    deactivated = 2
}

export interface IUser
{
    username: string;
    email: string;
    phone: string,
    status: eUserStatus;
    id?: string;
    name?: string;
    updatedAt?: Date;
    createdAt?: Date;
    fullName?: string;
}

export interface IUserWithRole extends IUser
{
    role: eUserRole;
}

export interface IUserFullInfo extends IUserWithRole
{
    wallet_source: eWalletType;
    wallet: IWallet;
    userWallets: IWallet[];
    kycInfo?: IKycDetailedStatusResponse;
}

export interface IUserRoleDetailed extends IUser
{
    role: IUserRole;
}

export enum eUserRole
{
    default = 101,
    admin = 100
}

export interface IGetUserResponse
{
    email: string;
    status: eUserStatus;
    name?: string;
    updatedAt?: Date;
    createdAt?: Date;
    role: eUserRole;
}

export interface IGetUsersParams 
{
    page?: number; //default: 1;
    count?: number; // default: 10; max: 100;
    sort_by: 'string'; //default: "created_at"
    sort_direction?: 'asc' | 'desc'; //default: "desc"
}



export interface IFullUserInfo extends IUser
{
    kycState: IUsersKyc | null;
    whitelist: IWallet | null;
}
export interface IGetUsersResponse 
{
    users: IFullUserInfo[];
    count: number;
}

export interface IUsersStats
{
    kyc: {
        verified: number,
        pending: number,
        rejected: number;
    },
    blacklist: {
        whitelisted: number,
        blacklisted: number,
        nonWhitelisted: number;
    },
    latestUsers: IUser[];
}
import { Routes } from '@angular/router';
import { adminGuardFn } from './guards/admin.guard';
import { authGuardFn } from './guards/auth.guard';
import { adminRoutes } from './modules/admin/admin.routes';

export const routes: Routes = [
    { path: '', loadComponent: () => import('./modules/home/home.component').then(m => m.HomeComponent) },
    { path: 'staking', loadComponent: () => import('./modules/staking/staking.component').then(m => m.StakingComponent) },
    {
        path: 'launchpad', children: [
            { path: '', loadComponent: () => import('./modules/launchpad/pages/launchpad-page/launchpad.component').then(m => m.LaunchpadComponent), pathMatch: 'full' },
            { path: ':id', loadComponent: () => import('./modules/launchpad/pages/project-detail/project-detail.component').then(m => m.ProjectDetailComponent) },
            { path: ':id/participate', loadComponent: () => import('./modules/launchpad/pages/participate-sale/participate-sale.component').then(m => m.ParticipateSaleComponent) }
        ]
    },
    { path: 'auth/callback', loadComponent: () => import('./modules/auth/callback/callback.component').then(m => m.CallbackComponent) },
    { path: 'maintenance', loadComponent: () => import('./modules/maintenance/maintenance-page/maintenance-page.component').then(m => m.MaintenancePageComponent) },
    {
        path: 'admin', children: adminRoutes, canActivate: [ authGuardFn, adminGuardFn ]
    },
    { path: 'participate', loadComponent: () => import('./modules/participate/participate.component').then(m => m.ParticipateComponent) },
    { path: 'participate-sale', loadComponent: () => import('./modules/participate/components/participate-sale/participate-sale.component').then(m => m.ParticipateSaleComponent) },
    { path: 'account', loadComponent: () => import('./modules/account/pages/account/account.component').then(m => m.AccountComponent) },
    { path: 'profile', loadComponent: () => import('./modules/account/pages/profile/profile.component').then(m => m.ProfileComponent) },

];
